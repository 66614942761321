<template>
  <div class="open-footer-global">
    <div class="open-footer-content">
      <div class="open-footer-content-list">
        <ul>
          <li>{{ $t("Footer.index.5iwc59zgc0w0") }}</li>
          <li>
            <!-- 这个是跳转不需要新页面打开 这个要改成新窗口打开 -->
            <a
              target="_blank"
              href="//open.jdl.com/#/devSupport/163068"
              >{{ $t("Footer.index.5iwc59zgdo81") }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="//open.jdl.com/#/devSupport/163067"
              >{{ $t("Footer.index.5iwc59zgdo82") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="open-footer-content-list">
        <ul>
          <li>{{ $t("Footer.index.5iwc59zgcog0") }}</li>
          <li>
            <a
              target="_blank"
              href="//open.jdl.com/#/devSupport/163062"
              >{{ $t("Footer.index.5iwc59zgdo83") }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="//open.jdl.com/#/devSupport/163063"
              >{{ $t("Footer.index.5iwc59zgdo84") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="open-footer-content-list">
        <ul>
          <li>{{ $t("Footer.index.5iwc59zgcsg0") }}</li>
          <li>
            <a target="_blank" href="/#/partner">{{
              $t("Footer.index.5iwc59zgcvc0")
            }}</a>
          </li>
        </ul>
      </div>
      <div class="open-footer-content-list">
        <ul>
          <li>{{ $t("Footer.index.5iwc59zgcy00") }}</li>
          <li>
            <a target="_blank" href="https://www.jdworldwide.com/jdl">{{
              $t("Footer.index.5iwc59zgdo85")
            }}</a>
          </li>
          <li>
            <a target="_blank" href="https://www.jdl.com/">{{
              $t("Footer.index.5iwc59zgdo86")
            }}</a>
          </li>
          <!-- <li>
            <a target="_blank" href="https://www.jdcloud.com/">{{
              $t("Footer.index.5iwc59zgdo87")
            }}</a>
          </li> -->
        </ul>
      </div>
      <div class="open-footer-connect-container">
        <ul class="open-footer-list">
          <li>{{ $t("Footer.index.5iwc59zgd0o0") }}</li>
          <li>
            {{ $t("Footer.index.5iwc59zgd3k0")
            }}<a href="tel: 400 156 2616">400 156 2616</a>
          </li>
          <li>
            {{ $t("Footer.index.5iwc59zgd5w0")
            }}<a href="mailto:jdwl_coop@jd.com">jdwl_coop@jd.com</a>
          </li>
          <li>{{ $t("Footer.index.5iwc59zgd8o0") }} 9:00-18:00</li>
        </ul>
      </div>
    </div>
    <div class="open-footer-license">
      <span class="open-footer-license-content">
        ©2004 - 2023 {{ $t("Footer.index.5iwc59zgdb80") }}
        {{ $t("Footer.index.5iwc59zgddo0") }}
        {{ $t("Footer.index.5iwc59zgdgg0") }}
        {{ $t("Footer.index.5iwc59zgdj00") }} 11011502005548
        {{ $t("Footer.index.5iwc59zgdlo0") }}
        <!-- 删除底部隐私协议 -->
        <!-- <a href="https://cloud.jdl.com/#/devSupport/53374" target="_blank">隐私协议</a> -->
      </span>
    </div>
    <el-dialog
      :visible.sync="wechatDialogVisible"
      width="490px"
      custom-class="qq-dialog"
    >
      <img src="@/assets/img/weChat.png" class="qq-wechart" />
      <span class="tip">{{ $t("Footer.index.5iwc59zgdo80") }}</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "open-footer",
  data() {
    return {
      wechatDialogVisible: false,
    };
  },
};
</script>


<style lang="scss">
@import "~@/assets/styles/top-font.scss";
@import "~@/assets/styles/mixin.scss";
.open-footer-global {
  // height: 317px;
  background: #f6f7fa;
  .open-footer-content {
    position: relative;
    padding: 50px 80px 0 80px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .open-footer-content-list {
      width: 170px;
      height: 187px;
      ul {
        li {
          width: 170px;
          margin-bottom: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          font-weight: normal;
          color: #666666;
          &:first-child {
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 15px;
          }
        }
      }
    }
    .open-footer-connect-container {
      position: absolute;
      right: 79px;
      top: 50px;
      height: 130px;
      width: 221px;
      .open-footer-connect {
        width: 208px;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        margin-bottom: 20px;
        .open-footer-wechat {
          @include transition-color;
          position: relative;
          padding-left: 23px;
          width: 125px;
          height: 32px;
          line-height: 31px;
          display: inline-block;
          font-size: 16px;
          font-weight: normal;
          color: #333333;
          text-align: center;
          border: 1px solid #979797;
          border-radius: 16px;
          .open-footer-wechat-icon {
            position: absolute;
            left: 10px;
            top: 7px;
            width: 33px;
            height: 18px;
            font-size: 18px;
            line-height: 18px;
            text-align: left;
            color: #999999;
          }
          &:hover {
            border-color: #3c6ef0;
            color: #3c6ef0;
            .open-footer-wechat-icon {
              color: #3c6ef0;
            }
          }
        }
      }
      .open-footer-list {
        li {
          line-height: 20px;
          font-size: 14px;
          font-weight: normal;
          color: #666666;
          margin-bottom: 10px;
          &:first-child {
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .open-footer-license {
    background-color: #373f5e;
    text-align: center;
    .open-footer-license-content {
      width: 1200px;
      margin: 0 auto;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      color: #999999;
      a {
        color: white;
      }
    }
  }
  .qq-dialog {
    text-align: center;
    background: #f6f7fc;
    .el-dialog__header {
      border-bottom: none;
    }
    .el-dialog__body {
      padding: 0 75px 40px;
    }
    .qq-wechart {
      display: block;
      width: 233px;
      height: auto;
      margin: 0 auto;
    }
    .tip {
      margin-top: 20px;
      display: inline-block;
      text-align: left;
    }
  }
}
</style>
