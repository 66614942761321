import { UserModule } from "@/store";
import isInternational from '@/utils/isInternational'

export default {
  methods: {
    // 成为开发者
    toBeDevelop() {
      const isDevelop = UserModule.user_develop;
      if (isDevelop === "yes") {
        UserModule.gotoAdmin();
      } else {
        if (UserModule.loginState === false) {
          UserModule.extranetCheckLogin();
          window.location.href = isInternational() ? `http://${window.location.host}/intl-admin/#/appManager/createApp` : `http://${window.location.host}/admin/#/appManager/createApp`;
        }
      }
    },
  },
};
